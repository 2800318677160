import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Jumbotron } from "reactstrap"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Nicht gefunden" />
    <Jumbotron className="bg-transparent">
      <h1>Es tut uns leid...</h1>
      <p>
        ... aber unter dieser Adresse haben wir keine passende Seite gefunden.
      </p>
      <p>
        Falls wir Ihnen persönlich weiter helfen können, dann{" "}
        <Link to="/kontakt">kontaktieren</Link> Sie uns bitte.
      </p>
    </Jumbotron>
  </Layout>
)

export default NotFoundPage
